import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Tabs,
  Tab,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
// routes
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { PERMISSIONS } from '../utils/constant';
import HolidaysTableToolbar from '../sections/@dashboard/holidays/HolidaysTableToolbar';
import { PATH_DASHBOARD } from '../routes/paths';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import ConfirmDialog from '../components/confirm-dialog';
import CustomBreadcrumbs from '../components/custom-breadcrumbs';
import { useSettingsContext } from '../components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../components/table';
import { deleteHoliday, fetchHolidays, updateDebouncedSearch } from '../store/slices/holidaySlice';
import HolidaysTableRow from '../sections/@dashboard/holidays/HolidaysTableRow';
import AddHolidays from '../sections/@dashboard/holidays/WorklogForm';
import { useSnackbar } from '../components/snackbar';

// sections

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'in active'];

const ROLE_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left', sortable: true },
  { id: 'date', label: 'Date', align: 'left', sortable: true },
  { id: 'day', label: 'Day', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: '', label: 'Actions', align: 'right' },
];

// ----------------------------------------------------------------------

export default function Holidays() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrder: 'asc' });

  const { themeStretch } = useSettingsContext();
  const dispatch = useDispatch();

  const {
    data: tableData,
    loading,
    debouncedSearch,
    pagination,
    permissions,
  } = useSelector((state) => ({
    data: state.holidays.data,
    loading: state.holidays.loading,
    debouncedSearch: state.holidays.debouncedSearch,
    pagination: state.holidays.pagination,
    permissions: state.auth.permissions,
  }));

  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [holidayModal, setHolidayModal] = useState(false);
  const [holidayId, setHolidayId] = useState('');
  const [debounceSearch, setDeboucedSearch] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
  });

  const onWorklogModalClose = () => {
    setHolidayModal(false);
    setHolidayId(null);
  };

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 52 : 72;

  const isFiltered = filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound = tableData.length <= 0;

  const handleOpenConfirm = () => setOpenConfirm(true);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleFilterStatus = (event, newValue) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event) => {
    setFilterName(event.target.value);
    if (!debouncedSearch) {
      dispatch(
        updateDebouncedSearch(
          debounce(() => {
            if (event) {
              setPage(0);
              setDeboucedSearch(event.target.value);
            }
          }, 500)
        )
      );
    }
    // eslint-disable-next-line no-unused-expressions
    debouncedSearch && debouncedSearch();
  };

  const handleFilterRole = (event) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = (id) => {
    dispatch(deleteHoliday(id))
      .unwrap()
      .then((res) => {
        enqueueSnackbar(res.message);
        dispatch(fetchHolidays());
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  const handleEditRow = (id) => {
    setHolidayId(id);
    setHolidayModal(true);
  };

  const handleResetFilter = () => {
    setFilterName('');
    setDeboucedSearch('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  useEffect(() => {
    dispatch(
      fetchHolidays({
        search: debounceSearch,
        page,
        rowsPerPage,
        status: filterStatus,
        order,
        orderBy,
      })
    );
  }, [debounceSearch, dispatch, filterStatus, order, orderBy, page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> {` Holidays | ${process.env.REACT_APP_NAME} `}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Holidays"
          links={[{ name: 'Management' }, { name: 'Holidays', href: PATH_DASHBOARD.holidays }]}
          action={
            permissions[PERMISSIONS.HOLIDAY_ADD] && (
              <Button
                onClick={() => setHolidayModal(true)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Holidays
              </Button>
            )
          }
        />
        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>

          <HolidaysTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterRole={filterRole}
            optionsRole={ROLE_OPTIONS}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            onResetFilter={handleResetFilter}
          />
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={pagination.total_records}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              {loading ? (
                <Box sx={{ width: 1 }}>
                  <LinearProgress />
                </Box>
              ) : (
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={pagination.total_records}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {tableData.map((row) => (
                        <HolidaysTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                        />
                      ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </>
                </Table>
              )}
            </Scrollbar>
          </TableContainer>
          <TablePaginationCustom
            count={pagination.total_records}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
      <Card>
        <Dialog
          open={holidayModal}
          onClose={() => {
            setHolidayModal(false);
            setHolidayId('');
          }}
        >
          <div
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop: '10px',
              paddingBottom: '30px',
              width: '100%',
            }}
          >
            <DialogTitle>{holidayId ? 'Update Holiday' : 'Add New Holiday'}</DialogTitle>
            <DialogContent>
              <AddHolidays
                onClose={onWorklogModalClose}
                updateId={holidayId}
                updateData={tableData.find((d) => d.id === holidayId)}
              />
            </DialogContent>
          </div>
        </Dialog>
      </Card>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <LoadingButton variant="contained" color="error" onClick={handleCloseConfirm}>
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

export const applyFilter = ({ inputData, comparator, filterName, filterStatus, filterRole }) => {
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    inputData = inputData.filter((user) => user.status === filterStatus);
  }

  if (filterRole !== 'all') {
    inputData = inputData.filter((user) => user.role === filterRole);
  }

  return inputData;
};
