import axios from '../../utils/axios';
import { statusValue } from '../utils/helper';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const initialState = {
  data: [],
  loading: false,
  debouncedSearch: null,
  pagination: {
    total_records: 0,
    current_page: 0,
    last_page: 0,
    per_page_records: 5,
  },
  holidayList: [],
};

export const fetchHolidays = createAsyncThunk(
  'holidays/fetchHolidays',
  async (params, { rejectWithValue }) => {
    const { page, rowsPerPage, order, orderBy, status, search } = params || {};
    try {
      const response = await axios.get('/holiday/list', {
        params: {
          search: search || '',
          sort_type: order,
          sort_column: orderBy || '',
          per_page_records: rowsPerPage || 5,
          page: page ? page + 1 : 1,
          status: statusValue(status),
        },
      });
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const addNewHoliday = createAsyncThunk(
  'holidays/addNewHoliday',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/holiday/add', data);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const deleteHoliday = createAsyncThunk(
  'holidays/deleteHoliday',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/holiday/delete/${id}`);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const updateExistingHoliday = createAsyncThunk(
  'holidays/updateExistingHoliday',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/holiday/update', data);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const fetchAllHolidays = createAsyncThunk(
  'holidays/fetchAllHolidays',
  async (params, { rejectWithValue }) => {
    const { order, search } = params || {};
    try {
      const response = await axios.get('/master/holiday/list', {
        params: {
          search: search || '',
          sort_type: order,
          sort_column: '',
          status: 1,
        },
      });
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

const holidaysSlice = createSlice({
  name: 'holidays',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidays.pending, (state, { payload }) => {
        state.loading = true;
        state.data = [];
      })
      .addCase(fetchHolidays.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.pagination = payload.pagination;
        state.loading = false;
      })
      .addCase(fetchHolidays.rejected, (state, { payload }) => {
        state.pagination = initialState.pagination;
        state.loading = false;
      })
      .addCase(fetchAllHolidays.pending, (state, { payload }) => {
        state.holidayList = [];
      })
      .addCase(fetchAllHolidays.fulfilled, (state, { payload }) => {
        state.holidayList = payload.data;
      })
      .addCase(fetchAllHolidays.rejected, (state, { payload }) => {
        state.holidayList = [];
      });
  },

  reducers: {
    updateDebouncedSearch: (state, { payload }) => {
      state.debouncedSearch = payload;
    },
  },
});

export const { updateDebouncedSearch } = holidaysSlice.actions;

export default holidaysSlice.reducer;
