export const PERMISSIONS = {
  PROFILE_VIEW: 'profile-view',
  PROFILE_UPDATE: 'profile-update',
  PROFILE_LIST: 'profile-list',
  PROFILE_ADD: 'profile-add',
  PROFILE_DETAILS: 'profile-details',
  PROFILE_DELETE: 'profile-delete',
  CHANGE_PASSWORD: 'change-password',
  ROLE_LIST: 'role-list',
  ROLE_DETAILS: 'role-details',
  ROLE_PERMISSIONS_UPDATE: 'role-permission-update',
  USER_LIST: 'user-list',
  USER_DETAILS: 'user-details',
  USER_ADD: 'user-add',
  USER_UPDATE: 'user-update',
  USER_STATUS_UPDATE: 'user-status-update',
  USER_DELETE: 'user-delete',
  PROJECT_LIST: 'project-list',
  HOLIDAY_LIST: 'holiday-list',
  HOLIDAY_ADD: 'holiday-add',
  HOLIDAY_UPDATE: 'holiday-update',
  HOLIDAY_DELETE: 'holiday-delete',
  HOLIDAY_DETAILS: 'holiday-details',
  WORKLOG_LIST: 'worklog-list',
  MY_TIMESHEET: 'my-timesheet',
  MY_TIMESHEET_HISTORY: 'my-timesheet-history',
  MY_TIMESHEET_ADD_UPDATE: 'my-timesheet-add-update',
  MY_TIMESHEET_SEND: 'my-timesheet-send',
  MY_TIMESHEET_EXPORT: 'my-timesheet-export',
  MY_PROFILE_UPDATE: 'my-profile-update',
  MANAGE_TIMESHEET: 'manage-timesheet',
  MANAGE_TIMESHEET_HISTORY: 'manage-timesheet-history',
  MANAGE_TIMESHEET_ADD_UPDATE: 'manage-timesheet-add-update',
  MANAGE_TIMESHEET_SEND: 'manage-timesheet-send',
  MANAGE_TIMESHEET_EXPORT: 'manage-timesheet-export',
};

export const ROLES = {
  SUPER_ADMIN: 'super-admin',
  EMPLOYEE: 'employee',
  HR_ADMIN: 'hr-admin',
};

export const RolesData = [
  {
    name: 'Employee',
    value: 'employee',
  },
  {
    name: 'HR Admin',
    value: 'hr-admin',
  },
];
