import * as Yup from 'yup';
import { unformatPhoneNumber } from '../store/utils/helper';

export const newUserSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  phone_number: Yup.string()
    .typeError('Phone number must be number')
    .test('valid number', 'Must be atleast 10 numbers', (value) => {
      const val = unformatPhoneNumber(value);
      return val.length === 10;
    })
    .required('Phone number is required'),
  phone_number_country_code: Yup.string().required('Country is required'),
  status: Yup.string().required('Status is required'),
  role_name: Yup.string().required('Role is required'),
});

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  password: Yup.string().required('Password is required').trim(),
});

export const resetPasswordSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
});

export const verifyOtpSchema = Yup.object().shape({
  otp: Yup.string()
    .required('Otp field is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
});

export const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export const addNewHolidaySchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  date: Yup.string().required('Date is required'),
  status: Yup.string().required('Status is required'),
  description: Yup.string().max(200, 'Description must not be more then 200 characters'),
});

export const updateUserSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),
  email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  phone_number: Yup.string()
    .typeError('Phone number must be number')
    .min(14, 'Must be more than 10 numbers')
    .required('Phone number is required'),
  last_name: Yup.string().required('Last Name is required'),
  phone_number_country_code: Yup.string().required('Country code is required'),
  profile_id: Yup.string(),
});

export const changePassWordSchema = Yup.object().shape({
  current_password: Yup.string().required('Current Password is required'),
  new_password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('New Password is required'),
  new_password_confirmation: Yup.string().oneOf(
    [Yup.ref('new_password'), null],
    'Passwords must match'
  ),
});

export const myTimesheetSchema = Yup.object({
  timeSheet: Yup.array().of(
    Yup.object().shape({
      date: Yup.string(),
      worked_hours: Yup.number()
        .lessThan(24, 'Hours must be less than 24')
        .typeError('Working hours must be a number')
        .min(0, 'Hours must be greater then 0'),
      is_holiday_or_on_leave: Yup.boolean().nullable().default(false),
    })
  ),
  description: Yup.string().required('Description is required'),
  emails: Yup.array()
    .of(Yup.string().email('Email must be a valid email address'))
    .min(1, 'Atleast 1 email is required'),
});

export const addProfileSchema = Yup.object().shape({
  emails: Yup.array()
    .of(Yup.string().email('Email must be a valid email address'))
    .min(1, 'Atleast 1 email is required'),
  default_hours: Yup.number()
    .required('Default hours is required')
    .lessThan(24, 'default hours must be less than 24')
    .typeError('Default hours is required'),
  description: Yup.string().required('Description is required'),
  users: Yup.array().of(
    Yup.object({
      name: Yup.string().required('The name field is required'),
      email: Yup.string()
        .required('Email is required')
        .email('Email must be a valid email address'),
    })
  ),
});
