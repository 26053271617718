import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.request.use(async (config) => {
  axios.defaults.timeout = 35000;
  try {
    let accessToken = await localStorage.getItem('accessToken');
    if (config.params?.authContext) {
      accessToken = await localStorage.getItem('accessToken')(config.params?.authContext);
      delete config.params.authContext;
    }

    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  } catch {
    console.error('Error');
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken');
      // eslint-disable-next-line no-return-assign
      return (window.location.href = '/login');
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
