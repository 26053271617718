import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAccessToken } from '../../utils/helper';
import axios from '../../utils/axios';

const initialState = {
  data: [],
  loading: false,
  timesheetList: [],
  pagination: {},
  timesheetId: '',
  allUsersList: [],
  manageTimesheetData: {},
  timesheetLoading: false,
};

export const addManageTimesheetLogs = createAsyncThunk(
  'manage-timesheet/AddManageTimesheetLogs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/manage-timesheet/update', data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getManageTimesheetLogs = createAsyncThunk(
  'manage-timesheet/getManageTimesheetLogs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/manage-timesheet', {
        params: data,
      });
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const sendLogsToEmails = createAsyncThunk(
  'manage-timesheet/sendLogsToEmails',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/manage-timesheet/send', data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.errors);
    }
  }
);

export const exportExcelSheet = createAsyncThunk(
  'manage-timesheet/exportExcelSheet',
  async ({ id, fileName, url }, { rejectWithValue }) => {
    const headers = {
      'Content-Type': 'blob',
      Authorization: `Bearer ${getAccessToken()}`,
    };
    const config = {
      method: 'GET',
      url: process.env.REACT_APP_HOST_API_KEY + url,
      params: { user_id: id },
      responseType: 'arraybuffer',
      headers,
    };
    try {
      const response = await axios(config);
      const outputFilename = `${fileName}.xlsx`;
      const newUrl = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = newUrl;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchTimesheetLogList = createAsyncThunk(
  'manage-timesheet/fetchTimesheetLogList',
  async (params, { rejectWithValue }) => {
    const { selectedUser, page, rowsPerPage, order, orderBy, search } = params || {};

    try {
      const response = await axios.get('/manage-timesheet/history', {
        params: {
          user_id: selectedUser,
          search: search || '',
          sort_type: order,
          sort_column: orderBy || '',
          per_page_records: rowsPerPage,
          page: page + 1,
        },
      });
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const getAllUserList = createAsyncThunk(
  'manage-timesheet/getAllUserList',
  async (params, { rejectWithValue }) => {
    const { role } = params || {};

    try {
      const response = await axios.get('/master/user/list', {
        params: {
          role,
        },
      });
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const manageTimeSheetSlice = createSlice({
  name: 'mmanage-timesheet',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addManageTimesheetLogs.pending, (state, { payload }) => {
        state.loading = true;
        state.timesheetId = '';
      })
      .addCase(addManageTimesheetLogs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.timesheetId = payload.data.id;
      })
      .addCase(addManageTimesheetLogs.rejected, (state, { payload }) => {
        state.loading = false;
        state.timesheetId = '';
      })
      .addCase(getManageTimesheetLogs.pending, (state, { payload }) => {
        state.timesheetLoading = true;
        state.manageTimesheetData = '';
      })
      .addCase(getManageTimesheetLogs.fulfilled, (state, { payload }) => {
        state.timesheetLoading = false;
        state.manageTimesheetData = payload.data;
      })
      .addCase(getManageTimesheetLogs.rejected, (state, { payload }) => {
        state.timesheetLoading = false;
        state.manageTimesheetData = '';
      })
      .addCase(getAllUserList.pending, (state, { payload }) => {
        state.allUsersList = [];
      })
      .addCase(getAllUserList.fulfilled, (state, { payload }) => {
        state.allUsersList = payload.data;
      })
      .addCase(getAllUserList.rejected, (state, { payload }) => {
        state.allUsersList = [];
      })
      .addCase(fetchTimesheetLogList.pending, (state, { payload }) => {
        state.loading = true;
        state.timesheetList = [];
        state.pagination = {};
      })
      .addCase(fetchTimesheetLogList.fulfilled, (state, { payload }) => {
        state.timesheetList = payload.data;
        state.loading = false;
        state.pagination = payload.pagination;
      })
      .addCase(fetchTimesheetLogList.rejected, (state, { payload }) => {
        state.pagination = {};
        state.timesheetList = [];
        state.loading = false;
      });
  },
  reducers: {},
});

export default manageTimeSheetSlice.reducer;
