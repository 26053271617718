import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import Page403 from '../pages/Page403';
// components
//

// ----------------------------------------------------------------------

AclGuard.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.string,
};

export default function AclGuard({ children, permissions }) {
  const { userPermissions } = useSelector((state) => ({
    userPermissions: state.auth.permissions,
  }));

  const data = useLocation();
  const { pathname } = data || {};

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!userPermissions[permissions]) {
    if (pathname !== requestedLocation) setRequestedLocation(pathname);
    return <Page403 />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
