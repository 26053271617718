// routes

import { useSelector } from 'react-redux';
import { PERMISSIONS, ROLES } from '../../../utils/constant';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  timesheet: icon('ic_file'),
  projects: icon('ic_folder'),
  holidays: icon('ic_calendar'),
  roles: icon('ic_lock'),
  profiles: icon('ic_file'),
};

const NavConfig = () => {
  const { permissions, user } = useSelector((state) => ({
    permissions: state.auth.permissions,
    user: state.auth.user,
  }));
  const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      items: [
        { title: 'Dashboard', path: PATH_DASHBOARD.home, icon: ICONS.dashboard, hidden: true },
        {
          title: 'My Timesheet',
          path: PATH_DASHBOARD.my_timesheet,
          icon: ICONS.timesheet,
          hidden:
            user?.role_name === ROLES.SUPER_ADMIN
              ? !!permissions[PERMISSIONS.MY_TIMESHEET]
              : (user?.role_name === ROLES.EMPLOYEE || user?.role_name === ROLES.HR_ADMIN) &&
                !permissions[PERMISSIONS.MY_TIMESHEET],
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      // subheader: 'management',
      items: [
        {
          title: 'users',
          path: PATH_DASHBOARD.user.root,
          icon: ICONS.user,
          hidden: !permissions[PERMISSIONS.USER_LIST],
        },
        {
          title: 'roles',
          path: PATH_DASHBOARD.roles,
          icon: ICONS.roles,
          hidden: !permissions[PERMISSIONS.ROLE_LIST],
        },
        {
          title: 'Holidays',
          path: PATH_DASHBOARD.holidays,
          icon: ICONS.holidays,
          hidden: !permissions[PERMISSIONS.HOLIDAY_LIST],
        },
        {
          title: 'Manage Timesheet',
          path: PATH_DASHBOARD.manage_timesheet,
          icon: ICONS.timesheet,
          hidden:
            user?.role_name === ROLES.EMPLOYEE
              ? true
              : (user?.role_name === ROLES.SUPER_ADMIN || user?.role_name === ROLES.HR_ADMIN) &&
                !permissions[PERMISSIONS.MANAGE_TIMESHEET],
        },
        {
          title: 'Manage Profiles',
          path: PATH_DASHBOARD.profiles.root,
          icon: ICONS.profiles,
          hidden: !permissions[PERMISSIONS.PROFILE_LIST],
        },
      ],
    },
  ];

  return navConfig;
};

export default NavConfig;
