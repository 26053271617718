import { Navigate, useRoutes } from 'react-router-dom';
import { getRoleName } from '../auth/utils';
import RoleBasedGuard from '../auth/RoleBasedGuard';
import UpdateProfile from '../pages/profiles/UpdateProfile';
import AddProfile from '../pages/profiles/AddProfile';
import Profiles from '../pages/Profiles';
import { PERMISSIONS, ROLES } from '../utils/constant';
import Page403 from '../pages/Page403';
import AclGuard from '../auth/AclGuard';
import Roles from '../pages/Roles';
import Holidays from '../pages/Holidays';
import Projects from '../pages/Projects';
import UserEditPage from '../pages/dashboard/UserEditPage';
import { ResetPasswordPage, VerifyOtp, NewPassword } from '../pages/auth';

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import {
  PATH_AFTER_LOGIN_FOR_EMPLOYEE,
  PATH_AFTER_LOGIN_NOT_FOR_EMPLOYEE,
  PATH_BEFORE_LOGIN,
} from '../config-global';

//
import {
  Page404,
  LoginPage,
  UserListPage,
  UserCreatePage,
  UserProfilePage,
  MyTimesheet,
  ManageTime,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return (
    <>
      {useRoutes([
        {
          path: '/',
          children: [
            { element: <Navigate to={PATH_BEFORE_LOGIN} replace />, index: true },
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <LoginPage />
                </GuestGuard>
              ),
            },
            {
              element: <CompactLayout />,
              children: [
                { path: 'reset-password', element: <ResetPasswordPage /> },
                { path: 'verify-otp', element: <VerifyOtp /> },
                { path: 'new-password', element: <NewPassword /> },
              ],
            },
          ],
        },
        {
          path: '/',
          element: (
            <AuthGuard permissions={['dashboard']}>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: (
                <Navigate
                  to={
                    getRoleName() === ROLES.EMPLOYEE
                      ? PATH_AFTER_LOGIN_FOR_EMPLOYEE
                      : PATH_AFTER_LOGIN_NOT_FOR_EMPLOYEE
                  }
                  replace
                />
              ),
              index: true,
            },
            // { path: 'home', element: <Dashboard /> },
            {
              path: 'manage-timesheet',
              element: (
                <AclGuard
                  permissions={getRoleName() !== ROLES.EMPLOYEE && PERMISSIONS.MANAGE_TIMESHEET}
                >
                  <ManageTime />
                </AclGuard>
              ),
            },
            {
              path: 'my-timesheet',
              element: (
                <RoleBasedGuard isRouter>
                  <AclGuard permissions={PERMISSIONS.MY_TIMESHEET}>
                    <MyTimesheet />
                  </AclGuard>
                </RoleBasedGuard>
              ),
            },
            {
              path: 'projects',
              element: (
                <AclGuard permissions={PERMISSIONS.PROJECT_LIST}>
                  <Projects />
                </AclGuard>
              ),
            },
            {
              path: 'holidays',
              element: (
                <AclGuard permissions={PERMISSIONS.HOLIDAY_LIST}>
                  <Holidays />
                </AclGuard>
              ),
            },
            {
              path: 'roles',
              element: (
                <AclGuard permissions={PERMISSIONS.ROLE_LIST}>
                  <Roles />
                </AclGuard>
              ),
            },
            {
              path: 'profile',
              element: (
                <AclGuard permissions={PERMISSIONS.PROFILE_VIEW}>
                  <UserProfilePage />
                </AclGuard>
              ),
            },
            {
              path: 'user',
              children: [
                { element: <Navigate to="/user/list" replace />, index: true },
                {
                  path: 'list',
                  element: (
                    <AclGuard permissions={PERMISSIONS.USER_LIST}>
                      <UserListPage />
                    </AclGuard>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <AclGuard permissions={PERMISSIONS.USER_ADD}>
                      <UserCreatePage />
                    </AclGuard>
                  ),
                },
                {
                  path: 'edit/:id',
                  element: (
                    <AclGuard permissions={PERMISSIONS.USER_UPDATE}>
                      <UserEditPage />
                    </AclGuard>
                  ),
                },
              ],
            },
            {
              path: 'manage-profiles',
              children: [
                { element: <Navigate to="/manage-profiles/list" replace />, index: true },

                {
                  path: 'list',
                  element: (
                    <AclGuard permissions={PERMISSIONS.PROFILE_LIST}>
                      <Profiles />
                    </AclGuard>
                  ),
                },
                {
                  path: 'new',
                  element: (
                    <AclGuard permissions={PERMISSIONS.PROFILE_ADD}>
                      <AddProfile />
                    </AclGuard>
                  ),
                },
                {
                  path: 'edit/:id',
                  element: (
                    <AclGuard permissions={PERMISSIONS.PROFILE_UPDATE}>
                      <UpdateProfile />
                    </AclGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          element: <CompactLayout />,
          children: [
            { path: '404', element: <Page404 /> },
            { path: '403', element: <Page403 /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ])}
    </>
  );
}
