// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '';
const ROOTS_AUTH = '';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verifyOtp: path(ROOTS_AUTH, '/verify-otp'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  home: path(ROOTS_DASHBOARD, '/home'),
  manage_timesheet: path(ROOTS_DASHBOARD, '/manage-timesheet'),
  projects: path(ROOTS_DASHBOARD, '/projects'),
  holidays: path(ROOTS_DASHBOARD, '/holidays'),
  roles: path(ROOTS_DASHBOARD, '/roles'),
  profiles: {
    root: path(ROOTS_DASHBOARD, '/manage-profiles'),
    new: path(ROOTS_DASHBOARD, '/manage-profiles/new'),
    list: path(ROOTS_DASHBOARD, '/manage-profiles/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/manage-profiles/edit/${id}`),
  },

  profile: path(ROOTS_DASHBOARD, '/profile'),
  my_timesheet: path(ROOTS_DASHBOARD, '/my-timesheet'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/edit/${id}`),
  },
};
