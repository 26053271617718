import {
  Box,
  Card,
  Checkbox,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSIONS } from '../../../utils/constant';
import { updateHasPermission } from '../../../store/slices/rolesSlice';

const PermissionsList = (role) => {
  const dispatch = useDispatch();
  const { currentRolePermissions, loading, permissions } = useSelector((state) => ({
    currentRolePermissions: state.roles.permissions,
    loading: state.roles.permissionsLoading,
    permissions: state.auth.permissions,
  }));

  const handleChange = (checked, index) => dispatch(updateHasPermission({ checked, index }));

  return (
    <Box>
      <Card sx={{ overflowY: 'auto' }}>
        <TableContainer>
          {loading ? (
            <Box sx={{ width: 1 }}>
              <LinearProgress />
            </Box>
          ) : (
            <Table aria-label="custom pagination table">
              <TableBody>
                {currentRolePermissions?.map((row, i) => (
                  <TableRow key={row.name} sx={{ fontWeight: 600 }}>
                    <TableCell component="th" scope="row">
                      <Checkbox
                        checked={row.has_permission}
                        onChange={(e) => handleChange(e.target.checked, i)}
                        disabled={!permissions[PERMISSIONS.ROLE_PERMISSIONS_UPDATE]}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Card>
    </Box>
  );
};

export default PermissionsList;
