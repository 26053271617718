import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { formatPhoneNumber } from '../../store/utils/helper';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
  defaultValue: PropTypes.string,
};

export default function RHFTextField({ name, defaultValue, helperText, ...other }) {
  const { control } = useFormContext();

  const getValue = (value) => {
    if (typeof value === 'number' && value === 0) return '';
    if (name === 'phone_number') return formatPhoneNumber(value);
    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={getValue(field.value)}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
