import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

const initialState = {
  data: [],
  loading: false,
  worklogList: [],
  updateWorklog: {},
  debouncedSearch: null,
  pagination: {
    total_records: 0,
    current_page: 0,
    last_page: 0,
    per_page_records: 5,
  },
};

export const addWorkLogs = createAsyncThunk(
  'timeSheet/addWorkLogs',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/worklog/add', data);
      return response.data;
    } catch (e) {
      console.error(e);
      throw rejectWithValue(e.errors);
    }
  }
);

export const fetchWorklogList = createAsyncThunk(
  'timeSheet/fetchWorklogList',
  async (params, { rejectWithValue }) => {
    const { page, rowsPerPage, search, filterRange } = params || {};
    try {
      const response = await axios.get('/worklog/list', {
        params: {
          search: search || '',
          page: page ? page + 1 : 1,
          per_page_records: rowsPerPage || 5,
          from_date: filterRange?.startDate || null,
          to_date: filterRange?.endDate || null,
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      throw rejectWithValue(e);
    }
  }
);

export const getWorklogById = createAsyncThunk(
  'timeSheet/getWorklogById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/worklog/details/${id}`);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const updateWorklog = createAsyncThunk(
  'timeSheet/updateWorklog',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/worklog/update/', data);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e.errors);
    }
  }
);

export const deleteWorklog = createAsyncThunk(
  'timeSheet/deleteWorklog',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/worklog/delete/${id}`);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e.errors);
    }
  }
);

const timeSheetSlice = createSlice({
  name: 'timesheet',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(addWorkLogs.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(addWorkLogs.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(addWorkLogs.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(fetchWorklogList.pending, (state, { payload }) => {
        state.loading = true;
        state.worklogList = [];
      })
      .addCase(fetchWorklogList.fulfilled, (state, { payload }) => {
        state.worklogList = payload.data;
        state.pagination = payload.pagination;
        state.loading = false;
      })
      .addCase(fetchWorklogList.rejected, (state, { payload }) => {
        state.worklogList = [];
        state.pagination = initialState.pagination;

        state.loading = false;
      })

      .addCase(getWorklogById.pending, (state, { payload }) => {
        state.updateWorklog = {};
      })
      .addCase(getWorklogById.fulfilled, (state, { payload }) => {
        state.updateWorklog = payload.data;
        state.loading = false;
      })
      .addCase(getWorklogById.rejected, (state, { payload }) => {
        state.updateWorklog = {};
        state.loading = false;
      });
  },
  reducers: {
    updateDebouncedSearch: (state, { payload }) => {
      state.debouncedSearch = payload;
    },
  },
});

export const { updateDebouncedSearch } = timeSheetSlice.actions;

export default timeSheetSlice.reducer;
