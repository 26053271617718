// @mui
import { Tooltip, Stack, Typography, Box } from '@mui/material';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative' }}>
        <Typography variant="h4">Sign in to QPortal</Typography>

        <Tooltip placement="left">
          <Box
            component="img"
            alt="jwt"
            src="/assets/icons/auth/ic_jwt.png"
            sx={{ width: 32, height: 32, position: 'absolute', right: 0, top: '-15px' }}
          />
        </Tooltip>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
