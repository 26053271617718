import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { authUserIdentity } from '../store/slices/authSlice';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/LoginPage';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const { isAuthenticated, isInitialized } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isInitialized: state.auth.isInitialized,
  }));

  useEffect(() => {
    dispatch(authUserIdentity());
  }, [dispatch]);

  const data = useLocation();
  const { pathname } = data || {};

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (isInitialized) return <LoadingScreen />;

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) setRequestedLocation(pathname);
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
