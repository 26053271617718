import { useNavigate } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { authUserForgotPassword } from '../../store/slices/authSlice';
import { resetPasswordSchema } from '../../utils/validation';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { useSnackbar } from '../../components/snackbar';
// ----------------------------------------------------------------------

export default function AuthResetPasswordForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));
  const methods = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });

  const { handleSubmit } = methods;

  const dispatch = useDispatch();

  const onSubmit = ({ email }) => {
    dispatch(
      authUserForgotPassword({
        email,
      })
    )
      .unwrap()
      .then((res) => {
        enqueueSnackbar(res.data.message);
        navigate(PATH_AUTH.verifyOtp, { replace: true });
        sessionStorage.setItem('email-recovery', email);
      })
      .catch((error) => enqueueSnackbar(error.errors.email[0], { variant: 'error' }));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField name="email" label="Email address" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={loading}
        sx={{ mt: 3 }}
      >
        Submit
      </LoadingButton>
    </FormProvider>
  );
}
