import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, MenuItem, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RolesData } from '../../../utils/constant';
import { formatPhoneNumber, unformatPhoneNumber } from '../../../store/utils/helper';
import { addNewUser, fetchProfileList, updateUserData } from '../../../store/slices/userSlice';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { newUserSchema } from '../../../utils/validation';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFSwitch, RHFTextField } from '../../../components/hook-form';

// ----------------------------------------------------------------------

UserNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default function UserNewEditForm({ isEdit = false, currentUser }) {
  const {
    first_name,
    last_name,
    email,
    phone_number,
    status,
    phone_number_country_code,
    role_name,
    profile_id,
    can_work_in_aws,
  } = currentUser || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, profileList } = useSelector((state) => ({
    loading: state.user.loading,
    profileList: state.user.profileList,
  }));
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const statuses = [
    { id: 1, activity: 'Active', value: 1 },
    { id: 2, activity: 'Inactive', value: 2 },
  ];
  const countries = [{ code: +1, label: 'USA' }];

  const defaultValues = useMemo(
    () => ({
      first_name: first_name || '',
      last_name: last_name || '',
      email: email || '',
      phone_number: formatPhoneNumber(phone_number) || '',
      phone_number_country_code: Number(phone_number_country_code) || +1,
      status: status || '',
      role_name: role_name || 'employee',
      profile_id: profile_id || '',
      can_work_in_aws: (can_work_in_aws === 1 && true) || false,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(newUserSchema),
    defaultValues,
  });

  const { reset, handleSubmit, setError } = methods;

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    dispatch(fetchProfileList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const onSubmit = async (values) => {
    const data = {
      ...values,
      can_work_in_aws: values.can_work_in_aws === true ? 1 : 0,
      phone_number: unformatPhoneNumber(values.phone_number),
      phone_number_country_code: `+${values.phone_number_country_code}`,
    };

    const postResponseProcess = (res) => {
      enqueueSnackbar(res.message, { variant: 'success' });
      navigate(PATH_DASHBOARD.user.list);
      reset();
    };

    if (isEdit) {
      dispatch(updateUserData({ ...data, user_id: id }))
        .unwrap()
        .then((res) => postResponseProcess(res))
        .catch(({ errors, message }) =>
          Object.keys(errors).map((error, index) => setError(error, { message: errors[error][0] }))
        );
    } else {
      dispatch(addNewUser({ data }))
        .unwrap()
        .then((res) => postResponseProcess(res))
        .catch(({ errors, message }) =>
          Object.keys(errors).map((error, index) => setError(error, { message: errors[error][0] }))
        );
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={10}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={4}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="first_name" label="First Name" />
              <RHFTextField name="last_name" label="Last Name" />
            </Box>
            <Box
              rowGap={3}
              columnGap={4}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
              sx={{ mt: 4 }}
            >
              <RHFTextField name="email" label="Email Address" disabled={isEdit} />
              <RHFSelect name="profile_id" label="Select Profile" placeholder="Select Profile">
                {profileList?.length > 0 ? (
                  profileList?.map((profile) => (
                    <MenuItem key={profile?.id} value={profile.id}>
                      {profile.profile_code}
                      <span style={{ fontSize: '14px', color: 'gray' }}>
                        &nbsp;({profile.default_hours}Hrs)
                      </span>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <span style={{ fontSize: '14px', color: 'gray' }}>No data available</span>
                  </MenuItem>
                )}
              </RHFSelect>
              <RHFSelect name="role_name" label="Select Role" placeholder="Select Role">
                {RolesData?.length > 0 ? (
                  RolesData?.map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <span style={{ fontSize: '14px', color: 'gray' }}>No data available</span>
                  </MenuItem>
                )}
              </RHFSelect>
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
              sx={{ mt: 4 }}
            >
              <RHFTextField
                select
                name="status"
                label="Status"
                placeholder="Status"
                disabled={isEdit}
              >
                {statuses.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.activity}
                  </MenuItem>
                ))}
              </RHFTextField>
              <RHFSelect
                name="phone_number_country_code"
                label="Country code"
                placeholder="Country code"
              >
                {countries.map((country) => (
                  <MenuItem key={country?.code} value={country.code}>
                    {country.label}
                    <span style={{ fontSize: '14px', color: 'gray' }}>
                      &nbsp;{`(+${country.code})`}
                    </span>
                  </MenuItem>
                ))}
              </RHFSelect>
              <RHFTextField name="phone_number" label="Phone Number" />
            </Box>
            <Box
              rowGap={3}
              columnGap={4}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
              sx={{ mt: 3 }}
            >
              <RHFSwitch name="can_work_in_aws" label="Can work on AWS?" />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Box display="flex" gap="20px">
                <Button onClick={() => navigate(PATH_DASHBOARD.user.list)}>Cancel</Button>
                <LoadingButton type="submit" variant="contained" loading={loading}>
                  {!isEdit ? 'Create User' : 'Save Changes'}
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
