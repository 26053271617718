import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// @mui
import { Container, LinearProgress } from '@mui/material';
// routes
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { fetchUserDetails } from '../../store/slices/userSlice';
import { useSnackbar } from '../../components/snackbar';
import { PATH_DASHBOARD } from '../../routes/paths';
// _mock_
// components
import { useSettingsContext } from '../../components/settings';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs';
// sections
import UserNewEditForm from '../../sections/@dashboard/user/UserNewEditForm';

// ----------------------------------------------------------------------

export default function UserEditPage() {
  const { themeStretch } = useSettingsContext();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState({});
  const { loading } = useSelector((state) => ({
    loading: state.user.loading,
  }));

  const fetchSingleEmp = async () => {
    await dispatch(fetchUserDetails(id))
      .unwrap()
      .then((res) => setCurrentUser(res.data))
      .catch((err) => enqueueSnackbar('Something went wrong!', { variant: 'error' }));
  };

  useEffect(() => {
    fetchSingleEmp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> {` User: Edit | ${process.env.REACT_APP_NAME} `}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Edit User"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'User',
              href: PATH_DASHBOARD.user.list,
            },
            { name: currentUser?.first_name },
          ]}
        />

        {loading ? (
          <Box sx={{ width: 1 }}>
            <LinearProgress />
          </Box>
        ) : (
          <UserNewEditForm isEdit currentUser={currentUser} />
        )}
      </Container>
    </>
  );
}
