/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
// utils
// routes
import { addNewProfile, updateProfile } from '../../store/slices/profilesSlice';
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { addProfileSchema } from '../../utils/validation';
import { useSnackbar } from '../../components/snackbar';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

CreateEditProfile.propTypes = {
  isEdit: PropTypes.bool,
  updateData: PropTypes.object,
};

export default function CreateEditProfile({ isEdit = false, updateData }) {
  const { default_hours, users, description } = updateData || {};

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { loading } = useSelector((state) => ({
    loading: state.profiles.loading,
  }));

  const defaultValues = useMemo(
    () => ({
      default_hours: default_hours || '',
      users: users || [
        {
          name: '',
          email: '',
        },
      ],
      description: description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateData]
  );

  const methods = useForm({
    resolver: yupResolver(addProfileSchema),
    defaultValues,
  });
  const { reset, handleSubmit, setError, control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  useEffect(() => {
    if (isEdit && updateData) reset(defaultValues);
    if (!isEdit) reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, updateData]);

  const postResponseProcess = (res) => {
    enqueueSnackbar(res.message, { variant: 'success' });
    navigate(PATH_DASHBOARD.profiles.list);
    reset();
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      default_hours: values.default_hours.toString(),
    };

    if (isEdit) {
      dispatch(updateProfile({ ...values, profile_id: updateData.id }))
        .unwrap()
        .then((res) => postResponseProcess(res))
        .catch(({ err, message }) =>
          Object.keys(err).map((error, index) => setError(error, { message: err[error][0] }))
        );
    } else {
      dispatch(addNewProfile(data))
        .unwrap()
        .then((res) => postResponseProcess(res))
        .catch(({ err, message }) =>
          Object.keys(err).map((error, index) => setError(error, { message: err[error][0] }))
        );
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={10}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={4}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
              }}
            >
              <Box>
                <Button
                  color="success"
                  sx={{ flexShrink: 0, width: 100, float: 'right', marginRight: 2 }}
                  onClick={() => append({ name: '', email: '' })}
                  startIcon={<Iconify icon="material-symbols:add" />}
                >
                  Add
                </Button>
              </Box>

              {fields.map((item, index) => (
                <Grid
                  container
                  spacing={2}
                  key={item.id}
                  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Grid item xs={12} sm={5}>
                    <RHFTextField name={`users.${index}.name`} label="Name" />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <RHFTextField name={`users.${index}.email`} label="Email address" />
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    {fields.length > 1 && (
                      <Button
                        color="error"
                        sx={{ flexShrink: 0 }}
                        onClick={() => remove(index)}
                        startIcon={<Iconify icon="ic:baseline-minus" />}
                      >
                        Remove
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}

              <RHFTextField name="default_hours" label="Default Hours" type="number" />
              <RHFTextField
                name="description"
                label="Add description"
                multiline
                type="textarea"
                rows={3}
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <Box display="flex" gap="20px">
                <Button onClick={() => navigate(PATH_DASHBOARD.profiles.list)}>Cancel</Button>
                <LoadingButton type="submit" variant="contained" loading={loading}>
                  {!isEdit ? 'Create Profile' : 'Save Changes'}
                </LoadingButton>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
