import PropTypes from 'prop-types';
// components
import { ROLES } from '../utils/constant';
// assets
import Page403 from '../pages/Page403';
import { getRoleName } from './utils';
//

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  isRouter: PropTypes.bool,
};

export default function RoleBasedGuard({ hasContent, roles, isRouter, children }) {
  // Logic here to get current user role

  const roles_data = [
    (getRoleName() === ROLES.EMPLOYEE || getRoleName() === ROLES.HR_ADMIN) && getRoleName(),
  ];
  const currentRole = getRoleName(); // admin;

  if (
    typeof roles !== 'undefined' &&
    (isRouter ? !roles_data.includes(currentRole) : !roles.includes(currentRole))
  ) {
    return <Page403 />;
  }

  return <> {children} </>;
}
