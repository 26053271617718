import PropTypes from 'prop-types';
// form
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, MenuItem, Button, TextField } from '@mui/material';
// components
import { Stack } from '@mui/system';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import moment from 'moment-jalaali';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import {
  addNewHoliday,
  fetchHolidays,
  updateExistingHoliday,
} from '../../../store/slices/holidaySlice';
import { addNewHolidaySchema } from '../../../utils/validation';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { useSnackbar } from '../../../components/snackbar';

// ----------------------------------------------------------------------

AddHolidays.propTypes = {
  onClose: PropTypes.func,
  updateId: PropTypes.number,
  updateData: PropTypes.object,
};

export default function AddHolidays({ onClose, updateId, updateData }) {
  const statuses = [
    { id: 1, activity: 'Active', value: 1 },
    { id: 2, activity: 'Inactive', value: 2 },
  ];
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    resolver: yupResolver(addNewHolidaySchema),
    defaultValues: {
      status: updateData?.status || '',
      date: updateData?.date || new Date(),
      name: updateData?.name || '',
      description: updateData?.description || '',
    },
  });

  const { handleSubmit, reset, setError, control } = methods;

  const afterRequestFulfilled = (res) => {
    dispatch(fetchHolidays());
    enqueueSnackbar(res.message);
    onClose();
    reset();
    setLoading(false);
  };

  const onSubmit = async (data) => {
    const submitData = {
      ...data,
      date: moment(data.date).format('YYYY-MM-DD'),
    };
    setLoading(true);
    if (updateId) {
      dispatch(updateExistingHoliday({ holiday_id: updateId, ...submitData }))
        .unwrap()
        .then((res) => afterRequestFulfilled(res))
        .catch((err) =>
          Object.keys(err).map((error, index) => setError(error, { message: err[error][0] }))
        );
    } else {
      dispatch(addNewHoliday(submitData))
        .unwrap()
        .then((res) => afterRequestFulfilled(res))
        .catch((err) =>
          Object.keys(err).map((error, index) => setError(error, { message: err[error][0] }))
        );
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box
          rowGap={3}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
          }}
          sx={{ mt: '10px', minWidth: { xs: '270px', sm: '400px' } }}
        >
          <RHFTextField name="name" label="Add holiday name" type="text" />
          <Controller
            name="date"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    {...field}
                    label="Date"
                    inputFormat="YYYY/MM/DD"
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            )}
          />
          <RHFSelect name="status" label="Status" placeholder="Status">
            {statuses.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.activity}
              </MenuItem>
            ))}
          </RHFSelect>
          <RHFTextField
            name="description"
            label="Add description"
            multiline
            type="textarea"
            rows={3}
          />
        </Box>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <Box display="flex" gap={{ xs: '10px', sm: '20px' }}>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton variant="contained" type="submit" color="info" loading={loading}>
              {updateId ? 'Update' : 'Submit'}
            </LoadingButton>
          </Box>
        </Stack>
      </Box>
    </FormProvider>
  );
}
