import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { useDispatch, useSelector } from 'react-redux';
import { authUserCreateNewPassword } from '../../store/slices/authSlice';
import { newPasswordSchema } from '../../utils/validation';
import { PATH_AUTH } from '../../routes/paths';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { useSnackbar } from '../../components/snackbar';

// ----------------------------------------------------------------------

export default function AuthNewPasswordForm() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));
  const [showPassword, setShowPassword] = useState(false);

  const defaultValues = {
    password: '',
    password_confirmation: '',
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(newPasswordSchema),
    defaultValues,
  });

  useEffect(() => {
    if (!sessionStorage.getItem('email-recovery')) {
      navigate(PATH_AUTH.login, { replace: true });
    }
  }, [navigate]);

  const { handleSubmit } = methods;

  const onSubmit = ({ password, password_confirmation }) => {
    const email = typeof window !== 'undefined' ? sessionStorage.getItem('email-recovery') : '';
    dispatch(
      authUserCreateNewPassword({
        email,
        password,
        password_confirmation,
      })
    )
      .unwrap()
      .then((res) => {
        enqueueSnackbar(res.message);
        navigate(PATH_AUTH.login);
        sessionStorage.removeItem('email-recovery');
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="password_confirmation"
          label="Confirm New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{ mt: 3 }}
        >
          Update Password
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
