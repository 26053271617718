import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

// DASHBOARD
export const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

// MANAGEMENT
export const ManageTimeSheet = Loadable(lazy(() => import('../pages/TimeSheet')));
export const ManageTime = Loadable(lazy(() => import('../pages/ManageTime')));
export const Projects = Loadable(lazy(() => import('../pages/Projects')));
export const Holidays = Loadable(lazy(() => import('../pages/Holidays')));
export const MyTimesheet = Loadable(lazy(() => import('../pages/MyTimeSheet')));
export const Roles = Loadable(lazy(() => import('../pages/Roles')));

// DASHBOARD: MANAGE-PROFILE
export const Profiles = Loadable(lazy(() => import('../pages/Profiles')));
export const AddProfile = Loadable(lazy(() => import('../pages/profiles/AddProfile')));
export const UpdateProfile = Loadable(lazy(() => import('../pages/profiles/UpdateProfile')));

// DASHBOARD: USER
export const UserListPage = Loadable(lazy(() => import('../pages/dashboard/UserListPage')));
export const UserProfilePage = Loadable(lazy(() => import('../pages/UserProfile')));
export const UserCreatePage = Loadable(lazy(() => import('../pages/dashboard/UserCreatePage')));
export const UserEditPage = Loadable(lazy(() => import('../pages/dashboard/UserEditPage')));

// ERROR PAGE
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
