// @mui
import { Container } from '@mui/material';
// assets
import { Helmet } from 'react-helmet-async';
import { PATH_DASHBOARD } from '../../routes/paths';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/CustomBreadcrumbs';
import { useSettingsContext } from '../../components/settings';
import CreateEditProfile from './CreateEditProfile';

// components

// ----------------------------------------------------------------------

export default function AddProfile() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> {` User: Create a new profile | ${process.env.REACT_APP_NAME} `}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Create a new profile"
          links={[
            { name: 'Management' },
            {
              name: 'Manage Profile',
              href: PATH_DASHBOARD.profiles.list,
            },
            { name: 'New Profile' },
          ]}
        />
        <CreateEditProfile />
      </Container>
    </>
  );
}
