import axios from '../../utils/axios';
import { statusValue } from '../utils/helper';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const initialState = {
  data: [],
  loading: false,
  debouncedSearch: null,
  projectsDropdownList: [],
  pagination: {
    total_records: 0,
    current_page: 0,
    last_page: 0,
    per_page_records: 5,
  },
};

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (params, { rejectWithValue }) => {
    const { page, rowsPerPage, search, status } = params || {};

    try {
      const response = await axios.get('/project/list', {
        params: {
          search: search || '',
          sort_type: 'asc',
          sort_column: '',
          per_page_records: rowsPerPage,
          page: page + 1,
          status: statusValue(status),
        },
      });
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const getProjectsDropdownList = createAsyncThunk(
  'projects/getProjectsDropdownList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/master/project/list');
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, { payload }) => {
        state.pagination = payload.pagination;
        state.data = payload.data;
        state.loading = false;
      })
      .addCase(fetchProjects.rejected, (state, { payload }) => {
        state.pagination = initialState.pagination;
        state.loading = false;
      })

      .addCase(getProjectsDropdownList.pending, (state, { payload }) => {
        state.projectDropDownList = [];
      })
      .addCase(getProjectsDropdownList.fulfilled, (state, { payload }) => {
        state.projectDropDownList = payload.data;
      })
      .addCase(getProjectsDropdownList.rejected, (state, { payload }) => {
        state.projectDropDownList = [];
      });
  },
  reducers: {
    updateDebouncedSearch: (state, { payload }) => {
      state.debouncedSearch = payload;
    },
  },
});

export const { updateDebouncedSearch } = projectsSlice.actions;

export default projectsSlice.reducer;
