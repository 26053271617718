import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { statusValue } from '../utils/helper';

const initialState = {
  data: [],
  loading: false,
  pagination: {
    total_records: 0,
    current_page: 0,
    last_page: 0,
    per_page_records: 5,
  },
  debouncedSearch: null,
  isProfileFetching: false,
  profileGettingDeleted: false,
};

export const addNewProfile = createAsyncThunk(
  'addNewProfile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/profile/add`, data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchProfileDetail = createAsyncThunk(
  'fetchProfileDetail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/profile/details/${id}`);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/profile/update', data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchProfiles = createAsyncThunk(
  'fetchProfiles',
  async (params, { rejectWithValue }) => {
    const { page, rowsPerPage, search, status, order, orderBy } = params || {};

    try {
      const response = await axios.get(`/profile/list`, {
        params: {
          search: search || '',
          sort_type: order || '',
          sort_column: orderBy || '',
          per_page_records: rowsPerPage || 5,
          page: page ? page + 1 : 0,
          status: statusValue(status),
        },
      });
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const deleteProfile = createAsyncThunk('deleteProfile', async (id, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/profile/delete/${id}`);
    return response.data;
  } catch (error) {
    throw rejectWithValue(error);
  }
});

const profilesSlice = createSlice({
  name: 'profilesSlice',
  initialState: { initialState },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfiles.pending, (state, { payload }) => {
        state.loading = true;
        state.data = [];
      })
      .addCase(fetchProfiles.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.data;
        state.pagination = payload.pagination;
      })
      .addCase(fetchProfiles.rejected, (state, { payload }) => {
        state.loading = false;
        state.data = [];
      })

      .addCase(addNewProfile.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(addNewProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(addNewProfile.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(updateProfile.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state, { payload }) => {
        state.loading = false;
      })

      .addCase(fetchProfileDetail.pending, (state, { payload }) => {
        state.isProfileFetching = true;
      })
      .addCase(fetchProfileDetail.fulfilled, (state, { payload }) => {
        state.isProfileFetching = false;
      })
      .addCase(fetchProfileDetail.rejected, (state, { payload }) => {
        state.isProfileFetching = false;
      });
  },

  reducers: {
    updateDebouncedSearch: (state, { payload }) => {
      state.debouncedSearch = payload;
    },
  },
});

export const { updateDebouncedSearch } = profilesSlice.actions;

export default profilesSlice.reducer;
