export const statusValue = (status) => {
  if (status && status !== 'all') {
    if (status === 'active') return 1;
    return 2;
  }
  return null;
};

export function formatPhoneNumber(phone) {
  let input = `${phone}`.replace(/\D/g, '');
  input = input.replace(/\D/g, '');
  const size = input.length;
  if (size > 0) input = `(${input}`;
  if (size > 3) input = `${input.slice(0, 4)}) ${input.slice(4, 11)}`;
  if (size > 6) input = `${input.slice(0, 9)}-${input.slice(9)}`;

  return input;
}

export const unformatPhoneNumber = (phoneNumber) =>
  `${phoneNumber}`.replace(/\D/g, '').slice(0, 10);
