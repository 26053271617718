import axios from '../../utils/axios';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const initialState = {
  roles: [],
  rolesLoading: false,
  permissions: [],
  permissionsLoading: false,
  loading: false,
  roleDetailsUpdating: false,
};

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get('/role/list');
    return response.data;
  } catch (e) {
    throw rejectWithValue(e);
  }
});

export const fetchRolePermissions = createAsyncThunk(
  'roles/fetchRolePermissions',
  async (role, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/role/details/${role}`);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const updateRolePermissions = createAsyncThunk(
  'roles/updateRolePermissions',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/role/update', data);
      return response.data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state, { payload }) => {
        state.rolesLoading = true;
      })
      .addCase(fetchRoles.fulfilled, (state, { payload }) => {
        state.roles = payload.data;
        state.rolesLoading = false;
      })
      .addCase(fetchRoles.rejected, (state, { payload }) => {
        state.rolesLoading = false;
      })

      .addCase(fetchRolePermissions.pending, (state, { payload }) => {
        state.permissionsLoading = true;
      })
      .addCase(fetchRolePermissions.fulfilled, (state, { payload }) => {
        state.permissions = payload.data;
        state.permissionsLoading = false;
      })
      .addCase(fetchRolePermissions.rejected, (state, { payload }) => {
        state.permissionsLoading = false;
      })

      .addCase(updateRolePermissions.pending, (state, { payload }) => {
        state.roleDetailsUpdating = true;
      })
      .addCase(updateRolePermissions.fulfilled, (state, { payload }) => {
        state.roleDetailsUpdating = false;
      })
      .addCase(updateRolePermissions.rejected, (state, { payload }) => {
        state.roleDetailsUpdating = false;
      });
  },
  reducers: {
    updateHasPermission: (state, { payload }) => {
      const { index, checked } = payload;
      state.permissions[index].has_permission = checked;
    },
  },
});

export const { updateHasPermission } = rolesSlice.actions;

export default rolesSlice.reducer;
