import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Button, TableRow, TableCell, IconButton, MenuItem } from '@mui/material';
// components
import * as Yup from 'yup';
import MenuPopover from '../../../components/menu-popover/MenuPopover';
import Iconify from '../../../components/iconify';
import ConfirmDialog from '../../../components/confirm-dialog';
import Label from '../../../components/label/Label';

export const newUserSchema = Yup.object().shape({
  project: Yup.string().required(''),
});
// ----------------------------------------------------------------------

ProjectsTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function ProjectsTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { name, status } = row;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => setOpenConfirm(true);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleOpenPopover = (event) => setOpenPopover(event.currentTarget);

  const handleClosePopover = () => setOpenPopover(null);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {name}
        </TableCell>

        <TableCell align="center">
          <Label
            variant="soft"
            color={(status === 1 && 'success') || 'error'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status === 1 ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Delete
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Status
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
