import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Button, TableRow, TableCell, IconButton, MenuItem } from '@mui/material';
// components
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from '../../../utils/constant';
import MenuPopover from '../../../components/menu-popover/MenuPopover';
import Iconify from '../../../components/iconify';
import ConfirmDialog from '../../../components/confirm-dialog';

export const newUserSchema = Yup.object().shape({
  project: Yup.string().required(''),
});
// ----------------------------------------------------------------------

RolesTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

export default function RolesTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { name, display_name, description, total_users } = row;
  const { permissions } = useSelector((state) => ({
    permissions: state.auth.permissions,
  }));

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleOpenPopover = (event) => setOpenPopover(event.currentTarget);

  const handleClosePopover = () => setOpenPopover(null);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {display_name}
        </TableCell>

        <TableCell align="center">{description}</TableCell>
        <TableCell align="center">{total_users}</TableCell>

        {permissions[PERMISSIONS.ROLE_DETAILS] && (
          <TableCell align="right">
            <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow(name);
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Details
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}
