// @mui
import { Box, Container, LinearProgress } from '@mui/material';
// assets
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fetchProfileDetail } from '../../store/slices/profilesSlice';
import { PATH_DASHBOARD } from '../../routes/paths';
import CustomBreadcrumbs from '../../components/custom-breadcrumbs/CustomBreadcrumbs';
import { useSettingsContext } from '../../components/settings';
import CreateEditProfile from './CreateEditProfile';

// ----------------------------------------------------------------------

export default function UpdateProfile() {
  const { themeStretch } = useSettingsContext();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [currentProfile, setCurrentProfile] = useState({});
  const { loading } = useSelector((state) => ({
    loading: state.profiles.isProfileFetching,
  }));

  useEffect(() => {
    dispatch(fetchProfileDetail(id))
      .unwrap()
      .then((res) => setCurrentProfile(res?.data))
      .catch((err) => enqueueSnackbar('Something went wrong!', { variant: 'error' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> {` User: Update profile | ${process.env.REACT_APP_NAME} `}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Update profile"
          links={[
            {
              name: 'Management',
            },
            {
              name: 'Manage Profile',
              href: PATH_DASHBOARD.profiles.list,
            },
            { name: 'New Profile' },
          ]}
        />
        {loading ? (
          <Box sx={{ width: 1 }}>
            <LinearProgress />
          </Box>
        ) : (
          <CreateEditProfile isEdit updateData={currentProfile} />
        )}
      </Container>
    </>
  );
}
