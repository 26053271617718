import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, FormHelperText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { PATH_AUTH } from '../../routes/paths';
// components
import { useSnackbar } from '../../components/snackbar';
import FormProvider, { RHFCodes } from '../../components/hook-form';
import { authUserVerifyOtp } from '../../store/slices/authSlice';

// ----------------------------------------------------------------------

export default function AuthVerifyCodeForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { loading } = useSelector((state) => ({
    loading: state.auth.loading,
  }));

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  };

  useEffect(() => {
    if (!sessionStorage.getItem('email-recovery')) {
      navigate(PATH_AUTH.login, { replace: true });
    }
  }, [navigate]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => {
    const sendOtp = {
      email: typeof window !== 'undefined' ? sessionStorage.getItem('email-recovery') : '',
      otp: Object.values(data).join(''),
    };
    dispatch(authUserVerifyOtp(sendOtp))
      .unwrap()
      .then((res) => {
        enqueueSnackbar(res.message);
        navigate(PATH_AUTH.newPassword, { replace: true });
      })
      .catch((error) => enqueueSnackbar('Invalid OTP', { variant: 'error' }));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFCodes keyName="code" inputs={['code1', 'code2', 'code3', 'code4', 'code5', 'code6']} />

        {(!!errors.code1 ||
          !!errors.code2 ||
          !!errors.code3 ||
          !!errors.code4 ||
          !!errors.code5 ||
          !!errors.code6) && (
          <FormHelperText error sx={{ px: 2 }}>
            Code is required
          </FormHelperText>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          sx={{ mt: 3 }}
        >
          Verify
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
