import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import holidaySlice from './slices/holidaySlice';
import projectSlice from './slices/projectSlice';
import timesheetSlice from './slices/timesheetSlice';
import userSlice from './slices/userSlice';
import rolesSlice from './slices/rolesSlice';
import profilesSlice from './slices/profilesSlice';
import myTimeSheetSlice from './slices/myTimesheetSlice';
import manageTimesheetSlice from './slices/manageTimesheetSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    projects: projectSlice,
    holidays: holidaySlice,
    user: userSlice,
    timesheet: timesheetSlice,
    roles: rolesSlice,
    profiles: profilesSlice,
    myTimesheet: myTimeSheetSlice,
    manageTimesheet: manageTimesheetSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
