import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { statusValue } from '../utils/helper';

const initialState = {
  userList: [],
  pagination: {
    total_records: 0,
    current_page: 0,
    last_page: 0,
    per_page_records: 5,
  },
  profileList: [],
  debouncedSearch: null,
  loading: false,
  singleEmp: {},
};

export const addNewUser = createAsyncThunk('addNewUser', async ({ data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/user/add`, data);
    return response.data;
  } catch (error) {
    throw rejectWithValue(error);
  }
});

export const fetchUserDetails = createAsyncThunk(
  'fetchUserDetails',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/user/details/${id}`);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchProfileList = createAsyncThunk(
  'fetchProfileList',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/master/profile/list`);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateUserData = createAsyncThunk(
  'updateUserData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/update', data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const fetchUserList = createAsyncThunk(
  'fetchUserList',
  async (data, { rejectWithValue }) => {
    const { status, page, rowsPerPage, search, order, orderBy } = data || {};
    try {
      const response = await axios.get(`/user/list`, {
        params: {
          status: statusValue(status) || '',
          search: search || '',
          sort_type: order || '',
          sort_column: orderBy || '',
          page: page ? page + 1 : 1,
          per_page_records: rowsPerPage || 5,
        },
      });
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  'updateUserStatus',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/status', data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: 'registerUser',
  initialState: { initialState },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserList.pending, (state, { payload }) => {
        state.loading = true;
        state.userList = [];
        state.pagination = initialState.pagination;
      })
      .addCase(fetchUserList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userList = payload?.data;
        state.pagination = payload.pagination;
      })
      .addCase(fetchUserList.rejected, (state, { payload }) => {
        state.loading = false;
        state.userList = [];
        state.pagination = initialState.pagination;
      })
      .addCase(updateUserStatus.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateUserStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateUserStatus.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(addNewUser.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(addNewUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.message = payload.message;
      })
      .addCase(addNewUser.rejected, (state, { payload }) => {
        state.message = Object.values(payload.errors)[0][0];
        state.loading = false;
      })
      .addCase(fetchUserDetails.pending, (state, { payload }) => {
        state.loading = true;
        state.singleEmp = {};
      })
      .addCase(fetchUserDetails.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.singleEmp = payload.data;
      })
      .addCase(fetchUserDetails.rejected, (state, { payload }) => {
        state.singleEmp = {};
        state.loading = false;
      })
      .addCase(updateUserData.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateUserData.fulfilled, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(updateUserData.rejected, (state, { payload }) => {
        state.loading = false;
      })
      .addCase(fetchProfileList.pending, (state, { payload }) => {
        state.profileList = [];
      })
      .addCase(fetchProfileList.fulfilled, (state, { payload }) => {
        state.profileList = payload.data;
      })
      .addCase(fetchProfileList.rejected, (state, { payload }) => {
        state.profileList = [];
      });
  },

  reducers: {
    updateDebouncedSearch: (state, { payload }) => {
      state.debouncedSearch = payload;
    },
  },
});

export const { updateDebouncedSearch } = userSlice.actions;

export default userSlice.reducer;
