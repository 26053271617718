import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isValidToken, setSession } from '../../auth/utils';
import axios from '../../utils/axios';
import localStorageAvailable from '../../utils/localStorageAvailable';
//

const authToken = localStorage.getItem('accessToken');

const initialState = {
  isInitialized: false,
  isAuthenticated: authToken && isValidToken(authToken),
  user: null,
  permissions: [],
  message: null,
  loading: false,
};

export const authUserLogin = createAsyncThunk(
  'auth/authLogin',
  async (data, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_HOST_API_KEY}/login`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  'auth/updateUserProfile',
  async (data, { rejectWithValue }) => {
    const url = `${process.env.REACT_APP_HOST_API_KEY}/profile-update`;
    try {
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.errors);
    }
  }
);

export const authUserLogout = createAsyncThunk('auth/authLogout', async ({ rejectWithValue }) => {
  const url = `${process.env.REACT_APP_HOST_API_KEY}/logout`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw rejectWithValue(error);
  }
});

export const authUserIdentity = createAsyncThunk(
  'auth/authUser',
  async (_, { rejectWithValue }) => {
    const storageAvailable = localStorageAvailable();
    const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
    const url = `${process.env.REACT_APP_HOST_API_KEY}/me`;

    if (accessToken && isValidToken(accessToken)) setSession(accessToken);

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const authUserForgotPassword = createAsyncThunk(
  'auth/authForgotPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/send-otp-to-reset-password', { email });
      return response;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const authUserVerifyOtp = createAsyncThunk(
  'auth/authUserVerifyOtp',
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/verify-reset-password-otp', { email, otp });
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const authUserCreateNewPassword = createAsyncThunk(
  'auth/authUserCreateNewPassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/reset-password', data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error);
    }
  }
);

export const authUserChangePassword = createAsyncThunk(
  'auth/authUserChangePassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/change-password', data);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error.errors);
    }
  }
);

const userAuthLoginSlice = createSlice({
  name: 'userAuth',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(authUserIdentity.pending, (state, action) => {
        state.isInitialized = true;
        state.isAuthenticated = false;
      })
      .addCase(authUserIdentity.fulfilled, (state, { payload }) => {
        const { permissions, ...user } = payload.data;
        state.user = user;
        state.permissions = permissions.reduce(
          (a, b) => ({
            ...a,
            [b]: 1,
          }),
          {}
        );
        state.isInitialized = false;
        state.isAuthenticated = true;
        localStorage.setItem('role', user?.role_name);
      })
      .addCase(authUserIdentity.rejected, (state, action) => {
        state.isInitialized = false;
        state.isAuthenticated = false;
      })

      .addCase(authUserLogout.pending, (state, action) => {
        state.isInitialized = true;
        state.isAuthenticated = false;
      })
      .addCase(authUserLogout.fulfilled, (state, action) => {
        state.isInitialized = false;
        state.isAuthenticated = false;
        setSession(null);
        localStorage.removeItem('role');
      })
      .addCase(authUserLogout.rejected, (state, action) => {
        state.isInitialized = false;
      })

      .addCase(authUserLogin.pending, (state, { payload }) => {
        state.loading = true;
        state.user = null;
      })
      .addCase(authUserLogin.fulfilled, (state, { payload }) => {
        state.user = payload.data;
        state.loading = false;
        state.isAuthenticated = true;
        state.message = payload.message;
        setSession(payload.data.personal_access_token);
        localStorage.setItem('role', payload.data.role_name);
      })
      .addCase(authUserLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.user = null;
        state.isAuthenticated = false;
        if (payload.errors.lenght > 0) {
          state.message = payload.errors[0];
        }
      })

      .addCase(authUserForgotPassword.pending, (state, action) => {
        state.isInitialized = true;
        state.loading = true;
      })
      .addCase(authUserForgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialized = false;
      })
      .addCase(authUserForgotPassword.rejected, (state, action) => {
        state.isInitialized = false;
        state.loading = false;
      })

      .addCase(authUserVerifyOtp.pending, (state, action) => {
        state.isInitialized = true;
        state.loading = true;
      })
      .addCase(authUserVerifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialized = false;
      })
      .addCase(authUserVerifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.isInitialized = false;
      })
      .addCase(authUserCreateNewPassword.pending, (state, action) => {
        state.loading = true;
        state.isInitialized = true;
      })
      .addCase(authUserCreateNewPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialized = false;
      })
      .addCase(authUserCreateNewPassword.rejected, (state, action) => {
        state.loading = false;
        state.isInitialized = false;
      })
      .addCase(authUserChangePassword.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(authUserChangePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(authUserChangePassword.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserProfile.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default userAuthLoginSlice.reducer;
