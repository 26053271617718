import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Button,
  TableRow,
  TableCell,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  Grid,
  Box,
} from '@mui/material';
// components
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from '../../../utils/constant';
import MenuPopover from '../../../components/menu-popover/MenuPopover';
import Iconify from '../../../components/iconify';
import ConfirmDialog from '../../../components/confirm-dialog';

export const newUserSchema = Yup.object().shape({
  project: Yup.string().required(''),
});
// ----------------------------------------------------------------------

ProfilesTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  onSelectRow: PropTypes.func,
};

const UserCountTableCell = (users) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <TableCell>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <Typography>{users?.users?.length}</Typography>
        <Box
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{ cursor: 'pointer', mt: 0.7 }}
        >
          <Iconify icon="ic:baseline-remove-red-eye" />
        </Box>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container spacing={6} sx={{ p: 3 }}>
          <Grid item>
            {users?.users.map((time, index) => (
              <Grid container key={index} sx={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <Grid item>
                  <Typography>
                    {time.name} : {time.email}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </TableCell>
  );
};

export default function ProfilesTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { profile_code, users, description, default_hours } = row;

  const { permissions } = useSelector((state) => ({
    permissions: state.auth.permissions,
  }));

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenConfirm = () => setOpenConfirm(true);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleOpenPopover = (event) => setOpenPopover(event.currentTarget);

  const handleClosePopover = () => setOpenPopover(null);

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {profile_code}
        </TableCell>
        <UserCountTableCell users={users} />
        <TableCell>{description}</TableCell>
        <TableCell align="center">{default_hours}</TableCell>

        {(permissions[PERMISSIONS.PROFILE_UPDATE] || permissions[PERMISSIONS.PROFILE_DELETE]) && (
          <TableCell align="right">
            <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {permissions[PERMISSIONS.PROFILE_DELETE] && (
          <MenuItem
            onClick={() => {
              handleOpenConfirm();
              handleClosePopover();
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon="eva:trash-2-outline" />
            Delete
          </MenuItem>
        )}

        {permissions[PERMISSIONS.PROFILE_UPDATE] && (
          <MenuItem
            onClick={() => {
              onEditRow();
              handleClosePopover();
            }}
          >
            <Iconify icon="eva:edit-fill" />
            Edit
          </MenuItem>
        )}
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}
