/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Card,
  Table,
  Button,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Tab,
  Tabs,
  LinearProgress,
} from '@mui/material';
// routes
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { debounce } from 'lodash';
import ProjectsTableToolbar from '../sections/@dashboard/projects/ProjectsTableToolbar';
import { fetchProjects, updateDebouncedSearch } from '../store/slices/projectSlice';
import ProjectsTableRow from '../sections/@dashboard/projects/ProjectsTableRow';
import { PATH_DASHBOARD } from '../routes/paths';
// _mock_
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import ConfirmDialog from '../components/confirm-dialog';
import CustomBreadcrumbs from '../components/custom-breadcrumbs';
import { useSettingsContext } from '../components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../components/table';

// sections

// ----------------------------------------------------------------------

const ROLE_OPTIONS = ['all', 'active', 'inactive'];

const STATUS_OPTIONS = ['all', 'active', 'inactive'];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'Actions', label: 'Actions', align: 'right' },
];

// ----------------------------------------------------------------------

export default function ProjectsPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettingsContext();
  const dispatch = useDispatch();

  const {
    data: tableData,
    loading,
    debouncedSearch,
    pagination,
  } = useSelector((state) => ({
    data: state.projects.data,
    loading: state.projects.loading,
    debouncedSearch: state.projects.debouncedSearch,
    pagination: state.projects.pagination,
  }));

  const navigate = useNavigate();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [debounceSearch, setDeboucedSearch] = useState(null);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
  });

  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const denseHeight = dense ? 52 : 72;

  const isFiltered = filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound = tableData.length <= 0;

  const handleOpenConfirm = () => setOpenConfirm(true);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleFilterStatus = (event, newValue) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event) => {
    setFilterName(event.target.value);
    if (!debouncedSearch) {
      dispatch(
        updateDebouncedSearch(
          debounce(() => {
            if (event) {
              setPage(0);
              setDeboucedSearch(event.target.value);
            }
          }, 500)
        )
      );
    }
    // eslint-disable-next-line no-unused-expressions
    debouncedSearch && debouncedSearch();
  };

  const handleFilterRole = (event) => {
    setPage(0);
    setFilterRole(event.target.value);
  };

  const handleDeleteRow = (id) => {
    // const deleteRow = tableData.filter((row) => row.id !== id);
    // setSelected([]);
    // setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  const handleEditRow = (id) => navigate(PATH_DASHBOARD.user.edit(paramCase(id)));

  const handleResetFilter = () => {
    setFilterName('');
    setDeboucedSearch(' ');
    setFilterRole('all');
    setFilterStatus('all');
  };

  const fetchProjectsData = useCallback(() => {
    dispatch(fetchProjects({ page, rowsPerPage, search: debounceSearch, status: filterStatus }));
  }, [debounceSearch, filterStatus, page, rowsPerPage]);

  useEffect(() => {
    fetchProjectsData();
  }, [debounceSearch, filterStatus, page, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> {` Projects | ${process.env.REACT_APP_NAME} `}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Projects"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Projects', href: PATH_DASHBOARD.projects.root },
          ]}
        />

        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Tabs>
          <ProjectsTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterRole={filterRole}
            optionsRole={ROLE_OPTIONS}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            onResetFilter={handleResetFilter}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={pagination.total_records}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              {loading ? (
                <Box sx={{ width: 1 }}>
                  <LinearProgress />
                </Box>
              ) : (
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={pagination.total_records}
                      numSelected={selected.length}
                      onSort={onSort}
                    />
                    <TableBody>
                      {tableData.map((row) => (
                        <ProjectsTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.name)}
                        />
                      ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </>
                </Table>
              )}
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={pagination.total_records}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

export const applyFilter = ({ inputData, comparator, filterName, filterStatus, filterRole }) => {
  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    inputData = inputData?.filter(
      (user) => user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    inputData = inputData.filter((user) => user.status === filterStatus);
  }

  if (filterRole !== 'all') {
    inputData = inputData.filter((user) => user.role === filterRole);
  }

  return inputData;
};
