import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ROLES } from '../utils/constant';
// components
import LoadingScreen from '../components/loading-screen';
import { getRoleName } from './utils';
import { PATH_NAVIGATE_FOR_EMPLOYEE, PATH_NAVIGATE_NOT_FOR_EMPLOYEE } from '../config-global';
//

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    isInitialized: state.auth.isInitialized,
  }));

  if (isAuthenticated) {
    return (
      <Navigate
        to={
          getRoleName() === ROLES.EMPLOYEE
            ? PATH_NAVIGATE_FOR_EMPLOYEE
            : PATH_NAVIGATE_NOT_FOR_EMPLOYEE
        }
      />
    );
  }

  if (isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
