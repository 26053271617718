/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';

// @mui
import {
  Card,
  Table,
  Button,
  TableBody,
  Container,
  TableContainer,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
// routes
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Box } from '@mui/system';

import { LoadingButton } from '@mui/lab';
import { PERMISSIONS } from '../utils/constant';
import {
  fetchRolePermissions,
  fetchRoles,
  updateRolePermissions,
} from '../store/slices/rolesSlice';
import PermissionsList from '../sections/@dashboard/roles/PermissionsList';
import RolesTableRow from '../sections/@dashboard/roles/RolesTableRow';
import { PATH_DASHBOARD } from '../routes/paths';
// _mock_
// components
import Scrollbar from '../components/scrollbar';
import ConfirmDialog from '../components/confirm-dialog';
import CustomBreadcrumbs from '../components/custom-breadcrumbs';
import { useSettingsContext } from '../components/settings';
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from '../components/table';

export default function Roles() {
  const {
    dense,
    page,
    order,
    orderBy,
    //
    selected,
    onSelectRow,
  } = useTable();

  const { themeStretch } = useSettingsContext();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { tableData, loading, currentRolePermissions, permissions, roleDetailsUpdating } =
    useSelector((state) => ({
      currentRolePermissions: state.roles.permissions,
      tableData: state.roles.roles,
      loading: state.roles.rolesLoading,
      permissions: state.auth.permissions,
      roleDetailsUpdating: state.roles.roleDetailsUpdating,
    }));

  const TABLE_HEAD = [
    { id: 'name', label: 'Role', align: 'left' },
    { id: 'description', label: 'Description', align: 'center' },
    { id: 'total_users', label: 'Total Users', align: 'center' },
    {
      id: 'Actions',
      label: 'Actions',
      align: 'right',
      hidden: !permissions[PERMISSIONS.ROLE_DETAILS],
    },
  ];

  const [openConfirm, setOpenConfirm] = useState(false);

  const [permissionsModal, setPermissionsModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const denseHeight = dense ? 52 : 72;

  const isNotFound = tableData.length <= 0;

  const handleCloseConfirm = () => setOpenConfirm(false);

  const selectedPermissions = currentRolePermissions
    .filter((p) => p.has_permission)
    .map((i) => i.name);

  const handleSubmit = () => {
    dispatch(
      updateRolePermissions({ role_name: selectedRole, new_permissions_name: selectedPermissions })
    )
      .unwrap()
      .then((res) => {
        enqueueSnackbar(res.message);
        setPermissionsModal(false);
        dispatch(fetchRoles());
      })
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  };

  const handleEditRow = (role) => {
    dispatch(fetchRolePermissions(role));
    setSelectedRole(role);
    setPermissionsModal(true);
  };

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  return (
    <>
      <Helmet>
        <title> {` Roles | ${process.env.REACT_APP_NAME} `}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Roles List"
          links={[{ name: 'Management' }, { name: 'Roles', href: PATH_DASHBOARD.roles }]}
        />

        <Card>
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              {loading ? (
                <Box sx={{ width: 1 }}>
                  <LinearProgress />
                </Box>
              ) : (
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 700 }}>
                  <>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      numSelected={selected.length}
                    />
                    <TableBody>
                      {tableData.map((row) => (
                        <RolesTableRow
                          key={row.id}
                          row={row}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onEditRow={() => handleEditRow(row.name)}
                        />
                      ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(page, tableData.length)}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </>
                </Table>
              )}
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>

      <Card>
        <Dialog
          open={permissionsModal}
          fullWidth
          maxWidth="md"
          onClose={() => {
            setPermissionsModal(false);
            setSelectedRole('');
          }}
          sx={{ paddingY: 3 }}
        >
          <DialogTitle sx={{ textTransform: 'capitalize', align: 'center', padding: 4 }}>
            {`${selectedRole || ''} Permissions List`}
          </DialogTitle>
          <DialogContent>
            <PermissionsList role={selectedRole} />
          </DialogContent>
          <DialogActions sx={{ padding: 4 }}>
            <Box display="flex" gap="20px" paddingTop="5px" paddingRight="5px">
              <Button onClick={() => setPermissionsModal(false)}>Close</Button>
              {permissions[PERMISSIONS.ROLE_PERMISSIONS_UPDATE] && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  loading={roleDetailsUpdating}
                >
                  Submit
                </LoadingButton>
              )}
            </Box>
          </DialogActions>
        </Dialog>
      </Card>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

export const applyFilter = ({ inputData, comparator, filterName, filterStatus, filterRole }) => {
  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  if (filterName) {
    inputData = inputData?.filter(
      (user) => user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    inputData = inputData.filter((user) => user.status === filterStatus);
  }

  if (filterRole !== 'all') {
    inputData = inputData.filter((user) => user.role === filterRole);
  }

  return inputData;
};
